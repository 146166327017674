<template>
    <div class="app-content">
        <!-- <div style="margin-bottom:20px">
      <el-card class="box-card">
        <b>
          {{title}}
        </b>
      </el-card>
    </div> -->
        <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="top"
        >
            <el-row type="flex" justify="center">
                <el-col :sm="20" :lg="16">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <b>问卷信息</b>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="模板" prop="wjtId">
                                    <el-select
                                        style="width: 100%"
                                        v-model="form.wjtId"
                                        placeholder="请选择"
                                        clearable
                                    >
                                        <el-option
                                            v-for="dict in companyTypeList"
                                            :key="dict.value"
                                            :label="dict.label"
                                            :value="dict.value"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="标题" prop="wjdoTitle">
                                    <el-input
                                        v-model="form.wjdoTitle"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="副标题"
                                    prop="wjdoTitleSub"
                                >
                                    <el-input
                                        v-model="form.wjdoTitleSub"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="期刊" prop="period">
                                    <el-input
                                        v-model="form.period"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="周期开始时间"
                                    prop="wjdoDateBegin"
                                >
                                    <el-date-picker
                                        v-model="form.wjdoDateBegin"
                                        type="date"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="周期结束时间"
                                    prop="wjdoDateEnd"
                                >
                                    <el-date-picker
                                        v-model="form.wjdoDateEnd"
                                        type="date"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="历史问卷ID"
                                    prop="wjdoIdHistory"
                                >
                                    <el-input
                                        v-model="form.wjdoIdHistory"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="前置问卷ID"
                                    prop="wjdoIdPrevious"
                                >
                                    <el-input
                                        v-model="form.wjdoIdPrevious"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="后置问卷ID"
                                    prop="wjdoIdNext"
                                >
                                    <el-input
                                        v-model="form.wjdoIdNext"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="投放顺序" prop="index">
                                    <el-input
                                        v-model="form.index"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="允许修改"
                                    prop="wjdoCanEdit"
                                >
                                    <el-select
                                        style="width: 100%"
                                        v-model="form.wjdoCanEdit"
                                        placeholder="请选择"
                                        clearable
                                    >
                                        <el-option
                                            v-for="dict in businessTypeList"
                                            :key="dict.value"
                                            :label="dict.label"
                                            :value="dict.value"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="启用" prop="status">
                                    <el-select
                                        style="width: 100%"
                                        v-model="form.status"
                                        placeholder="请选择"
                                        clearable
                                    >
                                        <el-option
                                            v-for="dict in businessTypeList"
                                            :key="dict.value"
                                            :label="dict.label"
                                            :value="dict.value"
                                        />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="活动口令" prop="gameCode">
                                    <el-input
                                        v-model="form.gameCode"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="16">
                                <el-form-item
                                    label="投放说明"
                                    prop="wjdoWjtProfile"
                                >
                                    <el-input
                                        type="textarea"
                                        :rows="5"
                                        v-model="form.wjdoWjtProfile"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card
                        class="box-card bottom-card"
                        style="margin-top: 10px"
                    >
                        <el-row type="flex" justify="end">
                            <!-- <el-button @click="resetForm('ruleForm')">取 消</el-button> -->
                            <el-button
                                type="primary"
                                @click="submitForm('ruleForm')"
                                >提 交</el-button
                            >
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { surveyAddApi, surveyUpdataApi, surveyDetailApi } from '@/api/survey.js'
import { templateListApi } from '@/api/templateApi.js'
export default {
    data() {
        return {
            companyTypeList: [],
            businessTypeList: [
                {
                    label: '是',
                    value: 1,
                },
                {
                    label: '否',
                    value: 0,
                },
            ],
            title: '新增模板',
            form: {
                gameCode:'LOTTERY'
            },
            rules: {
                wjtId: [
                    { required: true, message: '请选择模板', trigger: 'blur' },
                ],
                wjdoTitle: [
                    { required: true, message: '请输入标题', trigger: 'blur' },
                ],
                wjdoDateBegin: [
                    {
                        required: true,
                        message: '请选择开始时间',
                        trigger: 'blur',
                    },
                ],
                wjdoDateEnd: [
                    {
                        required: true,
                        message: '请选择结束时间',
                        trigger: 'blur',
                    },
                ],
                wjdoCanEdit: [
                    {
                        required: true,
                        message: '请选择是否允许修改',
                        trigger: 'blur',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: '请选择是否启用',
                        trigger: 'blur',
                    },
                ],
                gameCode: [
                    {
                        required: true,
                        message: '请输入活动口令',
                        trigger: 'blur',
                    },
                ],
                wjdoIdHistory: [
                    {
                        required: true,
                        message: '请输入历史问卷ID',
                        trigger: 'blur',
                    },
                ],
                wjdoIdPrevious: [
                    {
                        required: true,
                        message: '请输入前置问卷ID',
                        trigger: 'blur',
                    },
                ],
                period: [
                    {
                        required: true,
                        message: '请输入期刊',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    created() {
        this.getTemplatListFun()
        if (this.$route.query.id) {
            this.detailsurveyDetailApi()
        }
        console.log('222', sessionStorage.getItem('form'));
        if(sessionStorage.getItem('form')){
            this.form = JSON.parse(sessionStorage.getItem('form'))
        }
    },
    destroyed: function () {
        sessionStorage.setItem('form', JSON.stringify(this.form))
    },
    methods: {
        // 获取模板列表
        async getTemplatListFun() {
            var params = {
                start: 0,
                length: 1000,
                draw: 1,
                orderStr: 'time desc',
            }
            var res = await templateListApi(params)
            res.data.map((item) => {
                var obj = {
                    label: item.wjtName,
                    value: item.wjtId,
                }
                this.companyTypeList.push(obj)
            })
        },
        // 获取详情
        async detailsurveyDetailApi() {
            var params = {
                id: this.$route.query.id,
            }
            var res = await surveyDetailApi(params)
            if (res.code === 200) {
                this.form = res.data
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.$route.query.id) {
                        this.updatesurveyUpdataApi()
                    } else {
                        this.pushSurveyAddApi()
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 新增
        async pushSurveyAddApi() {
           
            var res = await surveyAddApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                })
                sessionStorage.removeItem('form')
                sessionStorage.clear()
                
                this.$router.go(-1)
            }
        },
        // 更新
        async updatesurveyUpdataApi() {
            this.form.time = ''
            var res = await surveyUpdataApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '修改成功',
                    type: 'success',
                })
                this.$router.go(-1)
            }
        },
    },
}
</script>

<style scoped>
.app-content {
    width: 1200px;
    margin: 20px auto 20px auto;
    /* margin-top: 20px; */
}
::v-deep .el-form-item {
    width: 100%;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>
